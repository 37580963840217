<template>
  <esmp-upload disabled type="drag">
    Загрузить файлы
  </esmp-upload>
</template>

<script>
export default {
  name: 'UploaderView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
